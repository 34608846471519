import React , {useState, useEffect} from 'react';
import { useLocation } from "@reach/router"

import Header from './../../container/Header/Header.js';
import Footer from './../../container/Footer/Footer.js';
import ReturnLink from './../../components/ReturnLink/ReturnLink.js';
import {Container, Row, Col, Button, Badge} from 'react-bootstrap';
import Input from './../../components/Input/Input.js';
import {ReactComponent as SVGRefresh} from '../../assets/image/refresh.svg';
import {ReactComponent as Times} from '../../assets/image/times.svg';
import CardEvent from '../../components/CardEvent/CardEvent.js'
import {Helmet} from "react-helmet";
import axios from "axios";
import variables from "./../../variables.js";

import './Programmation.scss'

export const useQuery = queryParam => {
    const search = new URLSearchParams(useLocation().search);
    return search.get(queryParam);
};

const Programmation = () => {
    const [results,changeResults] = useState(false);
    const [resultsNumber,changeResultsNumber] = useState(0);
    const [loadMoreLock,changeLoadMoreLock] = useState(false);
    const [searchRegion,changeSearchRegion] = useState(false);
    const [searchVille,changeSearchVille] = useState(false);
    const [searchActivites,changeSearchActivites] = useState(false);
    const [searchArtistes,changeSearchArtistes] = useState(false);
    const [choicesRegion,changeChoicesRegion] = useState(false);
    const [choicesVille,changeChoicesVille] = useState(false);
    const [choicesActivites,changeChoicesActivites] = useState(false);
    const [choicesArtistes,changeChoicesArtistes] = useState(false);
    const [date23,changeDate23] = useState(false);
    const [date24,changeDate24] = useState(false);
    const [userLat,changeUserLat] = useState(false);
    const [userLng,changeUserLng] = useState(false);
    const [defaultsChecked,changeDefaultsChecked] = useState(false);
    const urlSelectedRegion = useQuery("region");
    const urlSelectedCity = useQuery("ville");
    const urlParams = (window.location.search);
    let urlDefaults = new URLSearchParams(urlParams);
    //console.log(urlParams);
    useEffect(()=>{
        /* Testing /
        console.log('testcall');
        axios({
            method:'get',
            url:'https://paf.fetenationale.quebec/api/events?debug_cors=1',
            //headers:pixheaders
        }).then(function(response){
            console.log(response);
        })
        /* End test */
        if(!searchRegion && !searchVille && !searchActivites && !searchArtistes && !defaultsChecked){
            console.log('what')
            changeDefaultsChecked(true);
            let didSwitchSearch = false;
            if(urlDefaults.get('regions')){
                didSwitchSearch = true;
                changeSearchRegion(JSON.parse(urlDefaults.get('regions')))
            }
            if(urlDefaults.get('ville')){
                didSwitchSearch = true;
                changeSearchVille(JSON.parse(urlDefaults.get('ville')))
            }
            if(urlDefaults.get('activites')){
                didSwitchSearch = true;
                changeSearchActivites(JSON.parse(urlDefaults.get('activites')))
            }
            if(urlDefaults.get('artistes')){
                didSwitchSearch = true;
                changeSearchArtistes(JSON.parse(urlDefaults.get('artistes')))
            }
            if(urlDefaults.get('date23')){
                didSwitchSearch = true;
                changeDate23(true);
            }
            if(urlDefaults.get('date24')){
                didSwitchSearch = true;
                changeDate24(true);
            }
        }
        if ("geolocation" in navigator && !userLat && !userLng) {
            navigator.geolocation.getCurrentPosition(function(position) {
                changeUserLat(position.coords.latitude)
                changeUserLng(position.coords.longitude)
            })
        }
        if(!results && defaultsChecked){            
            //console.log('Update Results')
            loadMoreResults();
        }
        if(!choicesRegion){
            //console.log('Update Regions')
            axios({
                method:'get',
                url:variables.site+'/regions',
                //headers:pixheaders
            }).then(function(response){
                //console.log(response);
                if(response.status === 200){
                    //console.log(response.data.data);
                    var choices = [
                        //{value: 'all', label:'Tous'}
                    ];
                    response.data.data.map(function(region){
                        //console.log(region.name);
                        choices.push({value:region.id, label:region.name})
                        if(region.id == urlSelectedRegion){
                            changeSearchRegion({value:region.id, label:region.name});
                        }
                        return false;
                    })
                    
                    changeChoicesRegion(choices)
                }
            })
        }
        if(!choicesVille){
            //console.log('Update Ville')
            axios({
                method:'get',
                url:variables.site+'/cities',
                //headers:pixheaders
            }).then(function(response){
                //console.log(response);
                if(response.status === 200){
                    //console.log(response.data.data);
                    var choices = [
                        //{value: 'all', label:'Tous'}
                    ];
                    response.data.data.map(function(region){
                        //console.log(region.name);
                        choices.push({value:region.id, label:region.name});
                        return false;
                    })
                    changeChoicesVille(choices)
                }
            })
        }
        if(!choicesActivites){
            //console.log('Update Activites')
            axios({
                method:'get',
                url:variables.site+'/eventstypes',
                //headers:pixheaders
            }).then(function(response){
                //console.log(response);
                if(response.status === 200){
                    //console.log(response.data.data);
                    var choices = [
                        //{value: 'all', label:'Tous'}
                    ];
                    response.data.data.map(function(region){
                        //console.log(region.name);
                        var value = {value:region.id, label:region.name};
                        //if(!choices.includes(value)){
                            choices.push(value)
                        //}
                        return false;
                    })
                    changeChoicesActivites(choices)
                }
            })
        }
        if(!choicesArtistes){
            //console.log('Update Artistes')
            axios({
                method:'get',
                url:variables.site+'/artists',
                //headers:pixheaders
            }).then(function(response){
                //console.log(response);
                if(response.status === 200){
                    //console.log(response.data.data);
                    var choices = [
                        //{value: 'all', label:'Tous'}
                    ];
                    response.data.data.map(function(region){
                        //console.log(region.name);
                        var value = {value:region.id, label:region.name};
                        //if(!choices.includes(value)){
                            choices.push(value)
                        //}
                        return false;
                    })
                    changeChoicesArtistes(choices)
                }
            })
        }
    },[results,choicesArtistes,choicesActivites,choicesRegion,choicesVille,userLat,userLng])

    const removeSearch = (id,type) => {
        var work_array = [];
        var export_array = [];
        //read
        switch(type){
            case 'region':
                work_array = searchRegion;
            break;
            case 'ville':
                work_array = searchVille;
            break;
            case 'artistes':
                work_array = searchArtistes;
            break;
            case 'activites':
                work_array = searchActivites;
            break;
        }
        //change
        work_array.map((w)=>{
            if(w.value != id){
                export_array.push(w);
            }
        })
        //console.log(id);
        //console.log(type);
        //console.log(work_array);
        //write
        switch(type){
            case 'region':
                changeSearchRegion(export_array);
            break;
            case 'ville':
                changeSearchVille(export_array);
            break;
            case 'artistes':
                changeSearchArtistes(export_array);
            break;
            case 'activites':
                changeSearchActivites(export_array);                
            break;
        }
        resetPage();
    }
    const loadMoreResults = () => {
        /* URL Search */
        const {
            host, hostname, href, origin, pathname, port, protocol, search
        } = window.location
        //console.log(searchRegion);
        let queryString = '?search=1'
        if(searchRegion){
            queryString+='&regions='+encodeURIComponent(JSON.stringify(searchRegion));
        }
        if(searchVille){
            queryString+='&ville='+encodeURIComponent(JSON.stringify(searchVille));
        }
        if(searchActivites){
           queryString+='&activites='+encodeURIComponent(JSON.stringify(searchActivites));
        }
        if(searchArtistes){
            queryString+='&artistes='+encodeURIComponent(JSON.stringify(searchArtistes));
        }
        if(date23){
            queryString+='&date23='+date23;
        }
        if(date24){
            queryString+='&date24='+date24;
        }
        if(search != queryString){
            window.history.pushState({},"",pathname+queryString);
        }
        window.localStorage.setItem('currentsearch',pathname+queryString);
        /* end URL Search*/
        if(!loadMoreLock){
            changeLoadMoreLock(true);
            var searchParams = {
                offset:Array.isArray(results) ? results.length : 0,
                limit:12,
            }
            if(date23 && !date24){
                searchParams.date_event = '23';
            }else if(!date23 && date24){
                searchParams.date_event = '24';
            }
            if(Array.isArray(searchRegion)){
                //console.log(searchRegion);
                searchRegion.map((region)=>{
                    if(region.value!=='all'){
                        searchParams.region = region.value;
                    }
                    return false;
                })
            }
            if(searchVille){
                //console.log(searchVille);
                searchVille.map((region)=>{
                    if(region.value!=='all'){
                        searchParams.cities_id = region.value;
                    }
                    return false;
                })
            }
            if(searchArtistes){
                //console.log(searchArtistes);
                searchArtistes.map((region)=>{
                    if(region.value!=='all'){
                        searchParams.artists_id = region.value;
                    }
                    return false;
                })
            }
            if(searchActivites){
                //console.log(searchActivites);
                searchActivites.map((region)=>{
                    if(region.value!=='all'){
                        searchParams.eventstypes_id = region.value;
                    }
                    return false;
                })
            }
            //console.log(variables.site+'/events/');
            axios({
                method:'get',
                url:variables.site+'/events',
                params:searchParams
                //headers:pixheaders
            }).then(function(response){
                //console.log(response);
                //console.log(searchParams)
                if(response.status === 200){
                    //console.log(response.data.data);
                    //console.log(results);
                    if(Array.isArray(results)){
                        changeResults(
                            //results.cocat(response.data.data)
                            [...results,...response.data.data]
                        );
                    }else{
                        changeResults(response.data.data);
                    }
                    changeResultsNumber(response.data.total);
                }
                changeLoadMoreLock(false);
            })
        }
    }

    const resetPage = () => {
        changeLoadMoreLock(false);
        changeResults(false);
    }

    const resetForm = () => {
        changeSearchRegion();
        changeSearchVille();
        changeSearchActivites();
        changeSearchArtistes();
        changeDate23(false);
        changeDate24(false);
        resetPage();
    }
    return (<div className="page-programmation">
        <Helmet>
            <title>Programmation - Fête Nationale du Québec</title>
        </Helmet>
        <Header />
        <Container className="mt-md-14 mt-6 pb-md-18 pb-5">
            <ReturnLink href="https://fetenationale.quebec/" text="Retour au site principal" variant="a" />
            <h1 className="text-white mt-3 mb-6">Programmation complète</h1>
            <div className="searchForm">
                <Row className="mt-0">
                    <Col lg="3" md="6" sm="12">
                        <Input
                            className="mt-0"
                            label="Régions"
                            elementType="select" 
                            value={searchRegion}
                            changed={(e)=>{changeSearchRegion(e);resetPage();}}
                            isMulti
                            placeholder="Sélectionner une région"
                            options={choicesRegion}
                        />
                    </Col>
                    <Col lg="3" md="6" sm="12">
                        <Input
                            className="mt-0"
                            label="Ville"
                            elementType="select" 
                            value={searchVille}
                            changed={(e)=>{changeSearchVille(e);resetPage();}}
                            isMulti
                            placeholder="Sélectionner une ville"
                            options={choicesVille}
                        />
                    </Col>
                    <Col lg="3" md="6" sm="12">
                        <Input
                            className="mt-0"
                            label="Activités"
                            elementType="select" 
                            value={searchActivites}
                            changed={(e)=>{changeSearchActivites(e);resetPage();}}
                            isMulti
                            placeholder="Sélectionner une activité"
                            options={choicesActivites}
                        />
                    </Col>
                    <Col lg="3" md="6" sm="12">
                        <Input
                            className="mt-0"
                            label="Artistes"
                            elementType="select" 
                            value={searchArtistes}
                            changed={(e)=>{changeSearchArtistes(e);resetPage();}}
                            isMulti
                            placeholder="Sélectionner un artiste"
                            options={choicesArtistes}
                        />
                    </Col>
                </Row>
                <Row className="mt-1 inline-inputs">
                    <Col className="d-flex align-items-center">
                        <Input
                            className="mb-0"
                            label="23 juin"
                            elementType="checkbox" 
                            value='date23'
                            checked={date23}
                            changed={(e) => {
                                changeDate23(e.target.checked);
                                resetPage();
                            }}
                        />
                        <Input
                            className="mb-0"
                            label="24 juin"
                            elementType="checkbox" 
                            value='date24'
                            checked={date24}
                            changed={(e) => {
                                changeDate24(e.target.checked);
                                resetPage();
                            }}
                        />
                    </Col>
                    <Col className="text-right d-none">
                        <Button  variant="dark" size="md" onClick={resetForm}>Réinitialiser<SVGRefresh /></Button>
                    </Col>
                </Row>
            </div>
            <div className="searchRecap mt-3">
                    {(Array.isArray(searchRegion) && searchRegion.length !== 0) && searchRegion.map(region=>(
                        (<Badge className="mb-1" pill key={'region'+region.value} onClick={()=>{removeSearch(region.value,'region')}}>
                            {region.label}
                            <Times />
                        </Badge>)
                    ))}
                    {(Array.isArray(searchVille) && searchVille.length !== 0) && searchVille.map(region=>(
                        (<Badge className="mb-1" pill key={'ville'+region.value} onClick={()=>{removeSearch(region.value,'ville')}}>
                            {region.label}
                            <Times />
                        </Badge>)
                    ))}
                    {(Array.isArray(searchActivites) && searchActivites.length !== 0) && searchActivites.map(region=>(
                        (<Badge className="mb-1" pill key={'activity'+region.value} onClick={()=>{removeSearch(region.value,'activites')}}>
                            {region.label}
                            <Times />
                        </Badge>)
                    ))}
                    {(Array.isArray(searchArtistes) && searchArtistes.length !== 0) && searchArtistes.map(region=>(
                        (<Badge className="mb-1" pill key={'artist'+region.value} onClick={()=>{removeSearch(region.value,'artistes')}}>
                            {region.label}
                            <Times />
                        </Badge>)
                    ))}
            </div>
            {(Array.isArray(results)) ? (
                <div>
                    <Row className="mt-5">
                        <Col>
                            <h5 className="text-white">{resultsNumber} résultats</h5>
                        </Col>
                    </Row>
                    <div className="Events">
                        <Row className="mt-2">
                            {(Array.isArray(results) && results.length !== 0) && results.map(result=>(
                                <Col lg="3" md="4" sm="6" className="mb-3" key={result.id}>
                                    <CardEvent 
                                        id={result.id}
                                        date={result.date_event}
                                        thumbnail={result.image_src ? result.image_src : null }
                                        categorie={result.type_event}
                                        titre={result.name}
                                        location_lat={result.lat}
                                        location_lng={result.lng}
                                        userLat={userLat}
                                        userLng={userLng}
                                    />
                                </Col>
                            ))}
                        </Row>
                    </div>
                    <div className="text-center mt-3">
                        {(loadMoreLock) ? (
                            <Button variant="outline-primary" className="is-loading" size="lg">Charger plus de résultats</Button>
                        ):(
                            <Button variant="outline-primary" size="lg" onClick={()=>{loadMoreResults()}}>Charger plus de résultats</Button>
                        )}
                    </div>
                </div>
            ) : (
                <div className="text-white mt-8"><h2>Chargement</h2></div>
            )}
        </Container>
        <Footer />
    </div>);
}
export default Programmation;