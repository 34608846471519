import React, {useState, useRef,useEffect} from 'react';
import Select from 'react-select';
import './Input.scss';
//import {Button} from 'react-bootstrap';

import {ReactComponent as Chevron} from '../../assets/image/chevron_down.svg';

//import {IMaskInput} from 'react-imask';

const Input = (props) => {
    let {
        className="",
        elementType="text",
        label,
        defaultValue = "",
        changed,
        hideLabel=false,
        placeholder='Choisir...',
        options = [{ value:'',label:'Fournir des choix.'}],
        //mask='',
        append=false,
        prepend=false,
        checked=false,
        ...rest
    } = props;
    var value = false;
    if(props.value){
        value = props.value;
    }
    //console.log(value);
    var addedClasses = '';
    //delete props.append;
    const [elementValue,setElementValue] = useState(defaultValue);
    const [elementChecked,setElementChecked] = useState(checked);
    const [elementStatus,setElementStatus] = useState('normal');
    var onChangeFunction = changed;
    if(!onChangeFunction && elementType === 'checkbox'){
        onChangeFunction = (e) => {
            setElementChecked(e.target.checked);
            setIsOpen(false);
        }
    }else if(!onChangeFunction){
        onChangeFunction = (e) => {
            setElementValue(e.target.value);
            setIsOpen(false);
        }
    }
    if(elementType === 'checkbox'){
        if(!checked === elementChecked){
            setElementChecked(checked);
        }
    }
    var InputElement = null;
    //SELECT ELEMENTS
    const [isOpen,setIsOpen] = useState(false);
    //const [lock,setLock] = useState(false);
    const selectInputRef = useRef();
    //SWITCH DES ÉLÉMENTS
    switch (elementType){
        case 'textarea':
            InputElement = (
                <textarea 
                    onChange={onChangeFunction}
                    value={elementValue}
                    {...rest}
                >{elementValue}</textarea>
            )
            break;
        case 'select':
            var outputValue = '';
            //console.log(selectInputRef.current.select.getValue())
            if(Array.isArray(value)){
               value.forEach(el=>{
                    if(outputValue!==''){outputValue+=', ';}
                    outputValue+=el.label;
                })
            }
            //console.log(options);
            //console.log(options[0]);
            var selectClass = '';
            //console.log(options);
            if(options){
                InputElement = (
                    <div className={"SelectContainer "+selectClass}>
                        <div className="opener" onClick={(e) => {setIsOpen(!isOpen);}}>
                            <div>{outputValue ? outputValue : placeholder}</div>
                            <Chevron />
                        </div>
                        {isOpen &&
                            <div className="clickOutCatcher" onClick={(e) => {setIsOpen(false);}}></div>
                        }
                        {isOpen &&
                            <Select 
                                ref={selectInputRef}
                                onChange={onChangeFunction}
                                value={elementValue}
                                defaultValue={options[0]}
                                options={options}
                                autoFocus
                                backspaceRemovesValue={false}
                                controlShouldRenderValue={false}
                                hideSelectedOptions={false}
                                menuIsOpen
                                tabSelectsValue={false}
                                noOptionsMessage={()=>{return "Aucun résultat";}}
                                placeholder="Rechercher"
                                classNamePrefix="pix-react-select"
                                maxMenuHeight={200}
                                isClearable={false}
                                {...rest}
                                //style={{width:"100%"}}
                            /> 
                        }
                    </div>
                )
            }
                addedClasses += ' is-select';
                break;
        /*case 'mask':
            InputElement = (
                <IMaskInput 
                    mask={mask}
                    value={elementValue}
                    onChange={onChangeFunction}
                    onPaste={onChangeFunction}
                    {...rest}
                />
            )
            break;*/
        case 'checkbox':
            InputElement = (
                <input 
                    value={elementValue}
                    type="checkbox"
                    onChange={onChangeFunction}
                    checked={elementChecked}
                    {...rest}
                />
            );
            addedClasses += '';
            //console.log(elementChecked);
            if(elementChecked){
                addedClasses += ' is-checked';
            }
            break;
        case 'text' :
            if(elementStatus === 'focused'){
                addedClasses += ' is-filled';
            }
            InputElement = (
                <input 
                    value={elementValue}
                    type={elementType}
                    onChange={onChangeFunction}
                    onFocus={(e)=>{setElementStatus('focused')}}
                    onBlur={(e)=>{setElementStatus('normal')}}
                    {...rest}
                />
            )
            break;
        default :
            InputElement = (
                <input 
                    value={elementValue}
                    type={elementType}
                    onChange={onChangeFunction}
                    {...rest}
                />
            )
            break;
    }
    addedClasses += ' is-'+elementType;
    if(elementValue){
        addedClasses += ' is-filled';
    }
    var classesGroup="";
    if(prepend || append){
        classesGroup+=' input-group';
    }
    if(prepend){
        classesGroup+=' has-prepend';
    }
    if(append){
        classesGroup+=' has-append';
    }
    return (
        <div className={"Input " + className + ' ' + addedClasses}>
            {!hideLabel ?
                <label>
                    <div className="label">{label}</div>
                    <div className={classesGroup}>
                        {prepend && 
                            <div className="input-group-prepend">
                                {prepend}
                            </div>
                        }
                        {InputElement}
                        {append && 
                            <div className="input-group-append">
                                {append}
                            </div>
                        }
                    </div>
                </label>
            :
                <div className={classesGroup}>
                    {prepend && 
                        <div className="input-group-prepend">
                            {prepend}
                        </div>
                    }
                    {InputElement}
                    {append && 
                        <div className="input-group-append">
                            {append}
                        </div>
                    }
                </div>
            }
        </div>
    )
}

export default Input;