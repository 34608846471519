import React from 'react';

import {Row,Col,Container} from 'react-bootstrap';

import Logo from './../../assets/image/logo.png';
import GouvQC from './../../assets/image/logo_gouv_qc.png';
import HydroQC from './../../assets/image/logo_hydro_qc.png';
import MNQ from './../../assets/image/logo_mnq.png';
import Quebecor from './../../assets/image/logo_quebecor.png';
import SAQ from './../../assets/image/logo_saq.png';

import SocialLink from '../../components/SocialLink/SocialLink.js'

import './Footer.scss'

const Footer = () => {
    return (<footer className="Footer">
        {/*<div className="pt-13 pb-16 bg-white">
            <Container>
                <h3>Partenaires</h3>
                <div className="d-flex flex-wrap align-items-center justify-content-between mt-2">
                    <img src={GouvQC} className="mb-2" alt="Gouvernement du Québec" />
                    <img src={HydroQC} className="mb-2" alt="HydroQuébec" />
                    <img src={Quebecor} className="mb-2" alt="Québecor" />
                    <img src={SAQ} className="mb-2" alt="Société des Alcools du Québec" />
                    <img src={MNQ} className="mb-2" alt="Mouvement National des Québecois" />
                </div>
            </Container>
        </div>*/}
        <div className="pt-md-16 pt-6 bg-primary">
            <Container>
                <Row>
                    <Col md="3"><img src={Logo} alt="Fête Nationale du Québec" loading="lazy" className="Logo" /></Col>
                    <Col md="3" className="d-none d-md-block">
                        <ul className="FooterMenu">
                            <li><a href="/">Programmation complète</a></li>
                            <li><a href="https://fetenationale.quebec/capitale-nationale">Capitale Nationale</a></li>
                            <li><a href="https://fetenationale.quebec/gatineau/">Gatineau</a></li>
                            <li><a href="https://fetenationale-montreal.qc.ca/">Montréal</a></li>
                        </ul>
                    </Col>
                    <Col md="2" className="d-none d-md-block">
                        <ul className="FooterMenu">
                            <li><a href="https://fetenationale.quebec/a-propos">À propos</a>
                                <ul>
                                    <li><a href="https://fetenationale.quebec/a-propos/thematique-annuelle/">Thématique annuelle</a></li>
                                    <li><a href="https://fetenationale.quebec/a-propos/partenaires/">Patenaires</a></li>
                                    <li><a href="https://fetenationale.quebec/a-propos/histoire/la-fete-nationale-du-quebec-des-origines-a-nos-jours/">Histoire</a></li>
                                    <li><a href="https://fetenationale.quebec/a-propos/archives/">Archives</a></li>
                                </ul>
                            </li>
                        </ul>
                    </Col>
                    <Col md="2" className="d-none d-md-block">
                        <ul className="FooterMenu">
                            <li><a href="https://fetenationale.quebec/quiz/">Jeu questionnaire</a></li>
                            <li><a href="https://accentbleu.quebec/">Boutique</a></li>
                            <li><a href="https://fetenationale.quebec/concours-national/">Concours</a></li>
                        </ul>
                    </Col>
                    <Col md="2" className="d-none d-md-block">
                        <ul className="FooterMenu">
                            <li><a href="https://fetenationale.quebec/nouvelles/">Nouvelles</a></li>
                            <li><a href="https://fetenationale.quebec/salle-de-presse/">Salle de presse</a></li>
                            <li><a href="https://fetenationale.quebec/contact/">Contact</a></li>
                        </ul>
                    </Col>
                </Row>
                <div className="mt-3 d-block d-md-none">
                    <SocialLink variant="facebook" />
                    <SocialLink variant="instagram" />
                    <SocialLink variant="twitter" />
                    <SocialLink variant="youtube" />
                </div>
                <div  className="Colophon mt-5 pt-3 pb-3">
                    <div className="d-flex justify-content-between align-items-center">
                        <div>Fête nationale du Québec &copy; {new Date().getFullYear()} - <a href="https://pixelcircus.ca">Agence Web Pixel Circus</a></div>
                        <div className="text-right d-none d-md-block">
                            <SocialLink variant="facebook" />
                            <SocialLink variant="instagram" />
                            <SocialLink variant="twitter" />
                            <SocialLink variant="youtube" />
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    </footer>)
}

export default Footer;