import React,{useState, useEffect} from 'react';

//import {Card} from 'react-bootstrap';
import {navigate} from '@reach/router';

import Heart from '../../components/Heart/Heart.js'

import {ReactComponent as SvgPin} from '../../assets/image/location_outline.svg';

import './CardEvent.scss';

const CardEvent = (props) => {
    const {
        id = 0,
        date='xx',
        thumbnail=null,
        categorie='Catégorie',
        titre='Titre',
        location_lat = false,
        location_lng = false,
        userLat = false,
        userLng = false,
        //distance='X'
    } = props;
    let navigateTo = '/evenement/'+id;
    const dateObject = (date != '') ? date.split('-') : '';
    if(date != 'xx'){
        navigateTo = '/evenement/'+id+'/'+dateObject[0];
    }
    const ClickAction = () => {
        navigate(navigateTo);
    }
    const [distance,setDistance] = useState(false);
    let categWord = categorie;
    if(categWord == 'Local'){
        categWord = 'Locale';
    }else if(categWord == 'Régional'){
        categWord = 'Régionale';
    }
    /*useEffect(()=>{
        const calcCrow = (lat1, lon1, lat2, lon2) =>{
            var R = 6371; // km
            var dLat = toRad(lat2-lat1);
            var dLon = toRad(lon2-lon1);
            lat1 = toRad(lat1);
            lat2 = toRad(lat2);
            
            var a = Math.sin(dLat/2) * Math.sin(dLat/2) +
            Math.sin(dLon/2) * Math.sin(dLon/2) * Math.cos(lat1) * Math.cos(lat2); 
            var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
            var d = R * c;
            return d;
        }
        // Converts numeric degrees to radians
        const toRad = (Value) =>{
            return Value * Math.PI / 180;
        }
        if (userLat && userLng && location_lat && location_lng) {
            setDistance(Math.round(calcCrow(userLat,userLng,location_lat,location_lng)));
        }
    },[distance,location_lat,location_lng,userLng,userLat])*/
    //console.log(dateObject);
    return (
        <div className="CardEvent card">
            <div className="thumb" onClick={ClickAction}>
                {thumbnail && (<img src={'https://paf.fetenationale.quebec/'+thumbnail} loading="lazy" alt={titre} />)}
                <div className="date">
                    <div className="day">{dateObject[0]}</div>
                    <div className="month">juin</div>
                </div>
            </div>
            <div className="card-body">
                <div>
                    <div className="categ">
                        <h6 onClick={ClickAction}>{categWord}</h6>
                        {/*<Heart id={id} />*/}
                    </div>
                    <div onClick={ClickAction}><h5>{titre}</h5></div>
                </div>
                {distance && (
                    <div className="distance" onClick={ClickAction}>
                        <SvgPin />Distance {distance}km
                    </div>
                )}
            </div>
        </div>
    )
}
        
export default CardEvent;