import React, {useState,useEffect,useRef} from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
//import jQuery from 'jquery';

//import {Card} from 'react-bootstrap';

import Heart from '../../components/Heart/Heart.js'

import {ReactComponent as SocialFB} from '../../assets/image/facebook.svg';
import {ReactComponent as SocialTW} from '../../assets/image/twitter.svg';
import {ReactComponent as SocialMail} from '../../assets/image/mail.svg';

import './CardSubEvent.scss';

const CardSubEvent = (props) => {
    const {
        id = 0,
        datetime='23 juin - 19h',
        thumbnail=null,
        titre=null,
        adresse=null,
        categorie=null,
        subCategorie=null,
        cancelIfRain=false,
        cancelIfRainExtra=false,
        //minHeight=0
    } = props;
    const thumbElement = useRef(null)
    const [thumbHeight,setThumbHeight] = useState(0);
    const normalElement = useRef(null)
    const [normalElHeight,setNormalElHeight] = useState(0);
    const hoverElement = useRef(null)
    const [hoverElHeight,setHoverElHeight] = useState(0);
    /*useEffect(()=>{
        var thumbHeight = thumbElement.current.clientHeight;
        var normalHeight = normalElement.current.clientHeight;
        var hoverHeight = hoverElement.current.clientHeight;
        setNormalElHeight(normalHeight);
        setHoverElHeight(hoverHeight);
        var hoveredThumbHeight = thumbHeight - (hoverHeight - normalHeight);
        setThumbHeight(hoveredThumbHeight);
    }, [normalElHeight,hoverElHeight,thumbHeight])*/
    const redoThumbHeight = () => {
        var thumbHeight = thumbElement.current.clientHeight;
        var normalHeight = normalElement.current.clientHeight;
        var hoverHeight = hoverElement.current.clientHeight;
        setNormalElHeight(normalHeight);
        setHoverElHeight(hoverHeight);
        var hoveredThumbHeight = thumbHeight - (hoverHeight - normalHeight);
        /*console.log(hoveredThumbHeight)
        console.log(thumbHeight)
        console.log(normalHeight)
        console.log(hoverHeight)*/
        setThumbHeight(hoveredThumbHeight);
    }
    var defaultTitle = '';
    if(categorie){defaultTitle = categorie;}
    if(categorie && subCategorie){defaultTitle += " / "}
    if(subCategorie){defaultTitle += subCategorie}
    const CategorieText = () => {
        if(cancelIfRain === 'Déplacée'){
            return (
                <div className="categorie">
                    * En cas de pluie l'activité est déplacée au {cancelIfRainExtra}
                </div>
            )
        }else if(cancelIfRain){
            return (
                <div className="categorie">
                    * En cas de pluie l'activité est annulée
                </div>
            )
        }else{
            return (
                <div style={{height:"0px"}}></div>
            )
        }
    }

    return (
        <div className="CardSubEvent card">
            <div className="thumb" style={{maxHeight:thumbHeight}} ref={thumbElement}>
                {thumbnail ? (<LazyLoadImage afterLoad={()=>{
                    redoThumbHeight()
                }} src={thumbnail} alt={titre ? titre : defaultTitle}  />) : (<div className="emptyThumb" ref={thumbElement}></div>)}
                <div className="share">
                    <a href="https://facebook.com" target="_blank" rel="noreferrer" aria-label="Facebook" className="fb"><SocialFB /></a>
                    <a href="https://twitter.com" target="_blank" rel="noreferrer" aria-label="Twitter" className="tw"><SocialTW /></a>
                    <a href="mailto:test@test.ca" target="_blank" rel="noreferrer" aria-label="Email" className="mail"><SocialMail /></a>
                </div>
            </div>
            <div className="card-body">
                <div>
                    <div className="date">
                        <p>{datetime}</p>
                        <Heart id={id} />
                    </div>
                    <div className="card-title"><h5>{titre ? titre : defaultTitle}</h5></div>
                </div>
                <div>
                    <div className="normal" style={{maxHeight:normalElHeight}}><div ref={normalElement}>
                        <div className="categorie">{categorie}</div>
                    </div></div>
                    <div className="hover" style={{maxHeight:hoverElHeight}}><div ref={hoverElement}>
                        <div className="adresse">
                            <b>Adresse de l'activié</b><br />
                            <a href={"https://www.google.com/maps/search/"+adresse} target="_blank" rel="noreferrer">{adresse}</a>
                        </div>
                        <CategorieText />
                    </div></div>
                </div>
            </div>
        </div>
    )
}

export default CardSubEvent;