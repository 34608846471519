import React, {useState, useEffect} from 'react';

import Logo from './../../assets/image/logo.png';
import SocialLink from '../../components/SocialLink/SocialLink.js';
import {Row,Col} from 'react-bootstrap';

import './Header.scss'

const Header = () => {
    const [menuOpen,changeMenuOpen] = useState(false);
    var addedClass = '';
    if(menuOpen){
        addedClass = 'is-open';
    }
    useEffect(()=>{
        //console.log('effect');
        if(menuOpen){
            document.querySelectorAll('body')[0].classList.add('is-menu-open');
        }else{
            document.querySelectorAll('body')[0].classList.remove('is-menu-open');
        }
    },[menuOpen])
    
    return (<header className={"Header "+addedClass}>
        <div className="header-container pt-md-0 pt-3">
            <Row className="align-items-start">
                <Col><a href="https://www.fetenationale.quebec/" loading="lazy" aria-label="Mouvement national des Québecois"><img className="logo" src={Logo} alt="Mouvement National des Québecois" /></a></Col>
                <Col className="mainmenu ">
                    <div className="social d-none d-md-block">
                        <SocialLink variant="facebook" />
                        <SocialLink variant="instagram" />
                        <SocialLink variant="twitter" />
                        <SocialLink variant="youtube" />
                    </div>
                    <div className="opener" onClick={()=>{changeMenuOpen(!menuOpen)}}><span></span></div>
                </Col>
            </Row>
        </div>
        <div className="headingMenu">
            <div>
                <Row>
                    <Col className="">
                        <ul className="HeaderMainMenu">
                            <li><a href="/">Programmation complète</a></li>
                            <li><a href="https://fetenationale.quebec/capitale-nationale">Capitale Nationale</a></li>
                            <li><a href="https://fetenationale.quebec/gatineau/">Gatineau</a></li>
                            <li><a href="https://fetenationale-montreal.qc.ca/">Montréal</a></li>
                        </ul>
                    </Col>
                    <Col>
                        <ul className="HeaderSecMenu mb-8">
                            <li id="menu-item-927" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-927"><a href="https://fetenationale.quebec/a-propos/thematique-annuelle/">Thématique 2023</a></li>
                            <li id="menu-item-1619" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1619"><a href="https://fetenationale.quebec/a-propos/concours-dessins/">Concours de dessins</a></li>
                            <li id="menu-item-955" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-955"><a href="https://fetenationale.quebec/nouvelles/">Nouvelles</a></li>
                            <li id="menu-item-661" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-661"><a href="https://accentbleu.quebec/">Boutique</a></li>
                            <li id="menu-item-37" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-37"><a href="https://fetenationale.quebec/contact/">Contact</a></li>
                        </ul>
                        <div>
                            <SocialLink variant="facebook" />
                            <SocialLink variant="instagram" />
                            <SocialLink variant="twitter" />
                            <SocialLink variant="youtube" />
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    </header>);
}
export default Header;