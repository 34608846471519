import React from 'react';
import './PastilleDate.scss';

const PastilleDate = (props) => {
    const {
        mois = 'juin',
        date = 'xx'
    } = props;
    return (
        <div className="PastilleDate">
            <div className="date">{date}</div>
            <div className="mois">{mois}</div>
        </div>
    )
}
export default PastilleDate;