import React, {useState} from 'react';

import {Container, Row, Col, Button, Badge} from 'react-bootstrap';
import {ReactComponent as CircleChevron} from '../../assets/image/circle_chevron_right.svg';

import Input from '../../components/Input/Input.js';
import Heart from '../../components/Heart/Heart.js'
import CardEvent from '../../components/CardEvent/CardEvent.js'
import CardSubEvent from '../../components/CardSubEvent/CardSubEvent.js'
import SocialLink from '../../components/SocialLink/SocialLink.js'

const Styles = () => {
    const [selectedOption,setSelectedOption] = useState([]);
    //console.log(selectedOption);
    return <div>
        <Container style={{maxWidth:"calc(100% - 40px)"}}>
            <Row><Col><h1>MNQ - Programmes</h1></Col></Row>
            <Row><Col>
                <h1>Heading 1</h1>
                <h2>Heading 2</h2>
                <h3>Heading 3</h3>
                <h4>Heading 4</h4>
                <h5>Heading 5</h5>
                <h6>Heading 6</h6> 
                <p>Lorem ipsum dolor si amet</p>
            </Col></Row>
            <Row><Col><h4>Boutons</h4></Col></Row>
            <Row className="mb-3">
                <Col xs="1">Large</Col>
                <Col><Button variant="primary" size="lg">Button Text</Button></Col>
                <Col><Button variant="secondary" size="lg">Button Text</Button></Col>
                <Col><Button variant="primary" size="lg">Button Text <CircleChevron /></Button></Col>
                <Col><Button variant="secondary" size="lg">Button Text<CircleChevron /></Button></Col>
                <Col><Button variant="outline-primary" size="lg">Button Text</Button></Col>
                <Col><Button variant="outline-secondary" size="lg">Button Text<CircleChevron /></Button></Col>
            </Row>
            <Row className="mb-3">
                <Col xs="1">Medium</Col>
                <Col><Button variant="primary" size="">Button Text</Button></Col>
                <Col><Button variant="secondary" size="">Button Text</Button></Col>
                <Col><Button variant="primary" size="">Button Text<CircleChevron /></Button></Col>
                <Col><Button variant="secondary" size="">Button Text<CircleChevron /></Button></Col>
                <Col><Button variant="outline-primary" size="">Button Text</Button></Col>
                <Col><Button variant="outline-secondary" size="">Button Text<CircleChevron /></Button></Col>
            </Row>
            <Row className="mb-3">
                <Col xs="1">Small</Col>
                <Col><Button variant="primary" size="sm">Button Text</Button></Col>
                <Col><Button variant="secondary" size="sm">Button Text</Button></Col>
                <Col><Button variant="primary" size="sm">Button Text<CircleChevron /></Button></Col>
                <Col><Button variant="secondary" size="sm">Button Text<CircleChevron /></Button></Col>
                <Col><Button variant="outline-primary" size="sm">Button Text</Button></Col>
                <Col><Button variant="outline-secondary" size="sm">Button Text<CircleChevron /></Button></Col>
            </Row>
            <Row className="mb-3">
                <Col xs="1">Disabled</Col>
                <Col><Button variant="primary" size="" disabled>Button Text</Button></Col>
                <Col><Button variant="secondary" size="" disabled>Button Text</Button></Col>
                <Col><Button variant="primary" size="" disabled>Button Text<CircleChevron /></Button></Col>
                <Col><Button variant="secondary" size="" disabled>Button Text<CircleChevron /></Button></Col>
                <Col><Button variant="outline-primary" size="" disabled>Button Text</Button></Col>
                <Col><Button variant="outline-secondary" size="" disabled>Button Text<CircleChevron /></Button></Col>
            </Row>
            <Row className="mb-3">
                <Col xs="1">Loading</Col>
                <Col><Button variant="primary" size="" className="is-loading">Button Text</Button></Col>
                <Col><Button variant="secondary" size="" className="is-loading">Button Text</Button></Col>
                <Col><Button variant="primary" size="" className="is-loading">Button Text<CircleChevron /></Button></Col>
                <Col><Button variant="secondary" size="" className="is-loading">Button Text<CircleChevron /></Button></Col>
                <Col><Button variant="outline-primary" size="" className="is-loading">Button Text</Button></Col>
                <Col><Button variant="outline-secondary" size="" className="is-loading">Button Text<CircleChevron /></Button></Col>
            </Row>
        </Container>
        <div className="bgLightGray">
        <Container style={{maxWidth:"calc(100% - 40px)"}}>
            <Row><Col><h4>Select/Form</h4></Col></Row>
            <Row className="mb-3">
                <Col>
                    <Input
                        elementType="select" 
                        value={selectedOption}
                        changed={setSelectedOption}
                        isMulti
                        placeholder="Sélectionner"
                        options={[
                            {value: 'all', label:'Tous'},
                            {value: 'abitibi', label: 'Abitibi'},
                            {value: 'quebec', label: 'Québec'},
                            {value: 'saguenay', label: 'Saguenay'},
                            {value: 'montreal', label: 'Montréal'},
                            {value: 'laval', label: 'Laval'},
                            {value: 'monteregie', label: 'Montérégie'},
                        ]}
                    />
                </Col>
                <Col>
                    <Input elementType="checkbox" value="23juin" label="23 juin" name="23juin" />
                </Col>
                <Col>
                    <Input elementType="text" label="Placeholder" name="placeholder" />
                </Col>
            </Row>
            <Row><Col><h4>Likes</h4></Col></Row>
            <Row className="mb-3">
                <Col xs="1"><Heart liked={true} /></Col> 
                <Col xs="1"><Heart /></Col> 
            </Row>
            
                <Row><Col><h4>Pills</h4></Col></Row>
                <Row className="pb-3">
                    <Col>
                        <Badge pill>Inactive</Badge>
                        <Badge pill className="is-active">Active</Badge>
                    </Col>
                </Row>
                <Row className="pb-3" style={{maxWidth:"1295px"}}>
                     <h4>Card Events</h4>
                    <Row>
                        <Col>
                            <CardEvent 
                                id="234"
                                date="24"
                                thumbnail="https://loremflickr.com/640/360"
                                categorie="Fête Régionale"
                                titre="Fête nationale du Québec à Granby"
                                distance="13km"
                            />
                        </Col>
                        <Col>
                            <CardEvent 
                                id="234"
                                date="24"
                                thumbnail="https://loremflickr.com/640/361"
                                categorie="Fête Régionale"
                                titre="Fête nationale du Québec à Granby"
                                distance="13km"
                            />
                        </Col>
                        <Col>
                            <CardEvent 
                                id="234"
                                date="23"
                                thumbnail="https://loremflickr.com/640/362"
                                categorie="Fête Régionale"
                                titre="Fête nationale du Québec à Granby"
                                distance="13km"
                            />
                        </Col>
                    </Row>
                </Row>
                <Row className="pb-3" style={{maxWidth:"1295px"}}>
                    <Col>
                        <h4>Card Sub Events</h4>
                        <Row>
                            <Col xs="3">
                                <CardSubEvent
                                    id="234"
                                    thumbnail="https://loremflickr.com/640/360"
                                    datetime="24 juin — 13:00  à 16:00"
                                    titre="Jeux gonflables et maquillage"
                                    adresse="150 rue Jarry, Grandby"
                                    categorie="Jeux, concours et sport"
                                />
                            </Col>
                            <Col xs="3">
                                <CardSubEvent
                                    id="234"
                                    thumbnail="https://loremflickr.com/640/361"
                                    datetime="24 juin — 13:00  à 16:00"
                                    titre="Jeux gonflables et maquillage"
                                    adresse="150 rue Jarry, Grandby"
                                    categorie="Jeux, concours et sport"
                                    cancelIfRain
                                />
                            </Col>
                            <Col xs="3">
                                <CardSubEvent
                                    id="234"
                                    thumbnail="https://loremflickr.com/640/362"
                                    datetime="24 juin — 13:00  à 16:00"
                                    titre="Jeux gonflables et maquillage"
                                    adresse="150 rue Jarry, Grandby"
                                    categorie="Jeux, concours et sport"
                                />
                            </Col>
                            <Col xs="3">
                                <CardSubEvent
                                    id="234"
                                    thumbnail="https://loremflickr.com/640/363"
                                    datetime="24 juin — 13:00  à 16:00"
                                    titre="Jeux gonflables et maquillage"
                                    adresse="150 rue Jarry, Grandby"
                                    categorie="Jeux, concours et sport"
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </div>
        <div className="bg-primary">
            <Container style={{maxWidth:"calc(100% - 40px)"}}>
                <Row className="pb-3 pt-3">
                    <Col>
                        <h4>Liens Sociaux</h4>
                        <SocialLink variant="facebook" />
                        <SocialLink variant="instagram" />
                        <SocialLink variant="twitter" />
                        <SocialLink variant="youtube" />
                        <SocialLink variant="linkedin" />
                    </Col>
                </Row>
            </Container>
        </div>
    </div>
}

export default Styles;