import React, {useState,useEffect,useRef} from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
//import jQuery from 'jquery';

//import {Card} from 'react-bootstrap';

import {Row, Col} from 'react-bootstrap';

import PastilleDate from './../../components/PastilleDate/PastilleDate.js';
/*import Heart from '../../components/Heart/Heart.js'

import {ReactComponent as SocialFB} from '../../assets/image/facebook.svg';
import {ReactComponent as SocialTW} from '../../assets/image/twitter.svg';
import {ReactComponent as SocialMail} from '../../assets/image/mail.svg';*/
import {ReactComponent as Clock} from '../../assets/image/clock.svg';
import {ReactComponent as Pin} from '../../assets/image/pin.svg';

import './CardSubEventV3.scss';

const CardSubEvent = (props) => {
    const {
        id = 0,
        datetime='23 juin - 19h',
        heure_debut=false,
        heure_fin=false,
        date='TBA',
        thumbnail=null,
        titre=null,
        adresse=null,
        categorie=null,
        location_nicename="",
        subCategorie=null,
        cancelIfRain=false,
        cancelIfRainExtra=false,
        animation=null,
        releve=null,
        artiste=null,
        chorale=null,
        representants=null,
        //minHeight=0
    } = props;
    const thumbElement = useRef(null)
    const [thumbHeight,setThumbHeight] = useState(0);
    const normalElement = useRef(null)
    const [normalElHeight,setNormalElHeight] = useState(0);
    const hoverElement = useRef(null)
    const [hoverElHeight,setHoverElHeight] = useState(0);
    const [isOpen,setIsOpen] = useState(false);
    const day = date.split(' ')[0];
    /*useEffect(()=>{
        var thumbHeight = thumbElement.current.clientHeight;
        var normalHeight = normalElement.current.clientHeight;
        var hoverHeight = hoverElement.current.clientHeight;
        setNormalElHeight(normalHeight);
        setHoverElHeight(hoverHeight);
        var hoveredThumbHeight = thumbHeight - (hoverHeight - normalHeight);
        setThumbHeight(hoveredThumbHeight);
    }, [normalElHeight,hoverElHeight,thumbHeight])*/
    const redoThumbHeight = () => {
        var thumbHeight = thumbElement.current.clientHeight;
        var normalHeight = normalElement.current.clientHeight;
        var hoverHeight = hoverElement.current.clientHeight;
        setNormalElHeight(normalHeight);
        setHoverElHeight(hoverHeight);
        var hoveredThumbHeight = thumbHeight - (hoverHeight - normalHeight);
        /*console.log(hoveredThumbHeight)
        console.log(thumbHeight)
        console.log(normalHeight)
        console.log(hoverHeight)*/
        setThumbHeight(hoveredThumbHeight);
    }
    /*var defaultTitle = '';
    //if(categorie){defaultTitle = categorie;}
    //if(categorie && subCategorie){defaultTitle += " / "}
    if(subCategorie){defaultTitle += subCategorie}
    if(categorie && !subCategorie){defaultTitle = categorie;}
    let setTitre = "";
    if(artiste){
        setTitre = artiste;
    }else if(animation){
        setTitre = animation;
    }else if(titre){
        setTitre=titre;
    }else{
        setTitre=defaultTitle;
    }*/
    let setTitre='';
    //console.log('id:'+id+' titre:'+titre+' artiste:'+artiste+' releve:'+releve+' chorale:'+chorale+' animation:'+animation+' .')
    if(titre){
        setTitre=titre;
    }else{
        let nbEl = 0;
        if(artiste){
            setTitre = artiste;
            nbEl++;
        }
        if(releve){
            if(nbEl == 1){setTitre += ' - ';}
            setTitre += releve;
            nbEl++;
        }
        if(chorale && nbEl < 2){
            if(nbEl == 1){setTitre += ' - ';}
            setTitre += chorale;
            nbEl++;
        }
        if(animation && nbEl < 2){
            if(nbEl == 1){setTitre += ' - ';}
            setTitre += animation;
            nbEl++;
        }
        if(representants && nbEl < 2){
            if(nbEl == 1){setTitre += ' - ';}
            setTitre += representants;
            nbEl++;
        }
        if(setTitre === ''){
            if(categorie){setTitre = categorie;}
            if(subCategorie){setTitre = subCategorie}
            if(categorie && !subCategorie){setTitre = categorie;}
        }
    }
    let thumbRework = '';
    if(thumbnail.includes('|')){
        thumbRework = thumbnail.split('|');
        thumbRework = thumbRework[0];
    }else{
        thumbRework = thumbnail;
    }
    const CategorieText = () => {
        let cancelType = '';
        let explanation = ' - '+cancelIfRainExtra;
        if(cancelIfRain){
            switch(cancelIfRain){
                case '0':
                    cancelType = 'maintenue';
                    break;
                default:
                    cancelType = cancelIfRain.toLowerCase();
                    break;
            }
        }
        if(cancelType !== ''){
            return (
                <div className="categorie">
                    * En cas de pluie l'activité est {cancelType}
                    {cancelIfRainExtra ? explanation : '' }
                </div>
            )
        }else{
            return (
                <div style={{height:"0px"}}></div>
            )
        }
    }
    
    return (
        <div className="CardSubEventV3 card">
            <div className="thumb">
                {thumbRework ? (<LazyLoadImage src={thumbRework} alt={setTitre}  />) : (<div className="emptyThumb" ref={thumbElement}></div>)}
                <PastilleDate date={day} />
            </div>
            <div className="card-body">
                <div>
                    {categorie != setTitre ? (<div className="categ">{categorie}</div>) : ('')}
                    <div className="card-title"><h5>{setTitre}</h5></div>
                    {subCategorie != setTitre ? (<div className="categ2">{subCategorie}</div>) : ('')}
                </div>
                <div>
                <Row className="additionnal-info">
                    <Col xs="3" className="time">
                        <Clock />
                        {heure_debut}
                        {(heure_fin) ? 'à '+heure_fin : ''}
                    </Col>
                    <Col xs="8" className="place">
                        <Pin />
                        <a href={"https://www.google.com/maps/search/"+adresse} target="_blank" rel="noreferrer">{location_nicename}</a>
                    </Col>
                    <Col xs="1">
                        {cancelIfRain ? <div className="toggle text-end" onClick={()=>{setIsOpen(!isOpen)}}>{(isOpen) ? '-' : '+'}</div> : ''}
                    </Col>
                </Row>
                {isOpen ? <div className="rainInfo">
                    <CategorieText />
                </div> : ''}
                </div>
            </div>
        </div>
    )
}

export default CardSubEvent;