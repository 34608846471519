import React from "react";

import {ReactComponent as SVGFacebook} from '../../assets/image/facebook.svg';
import {ReactComponent as SVGTwitter} from '../../assets/image/twitter.svg';
import {ReactComponent as SVGInstagram} from '../../assets/image/instagram.svg';
import {ReactComponent as SVGYoutube} from '../../assets/image/youtube.svg';
import {ReactComponent as SVGLinkedin} from '../../assets/image/LinkedIn.svg';
import './SocialLink.scss';

const SocialLink = (props) => {
    const {
        variant = false,
    } = props
    var icon,url = '';
    switch (variant){
        case 'facebook':
            url = 'https://www.facebook.com/LaFetenationaleduQuebec';
            icon = <SVGFacebook />
            break;
        case 'twitter':
            url = 'https://twitter.com/fetenationale';
            icon = <SVGTwitter />
            break;
        case 'instagram':
            url = 'https://www.instagram.com/fetenationale';
            icon = <SVGInstagram />
            break;
        case 'youtube':
            url = 'https://www.youtube.com/fetenationale';
            icon = <SVGYoutube />
            break;
        case 'linkedin':
            url = 'https://linkedin.com';
            icon = <SVGLinkedin />
            break;
        default:
            return false;
    }
    return <a href={url} className={"SocialLink is-"+variant} aria-label={variant}>{icon}</a>
}

export default SocialLink;