import React, { useState, useEffect } from 'react';

import { ReactComponent as Heart } from '../../assets/image/heart.svg'
import "./Heart.scss";

const LikeButton = (props) => {
    const [liked, setLiked] = useState('toset');
    useEffect(() => {
        if (liked === 'toset') {
            var likes = localStorage.getItem('likes')
            if (likes) {
                likes = likes.split(',')
                if (likes.includes(props.id)) {
                    setLiked(true);
                } else {
                    setLiked(false);
                }
            } else {
                setLiked(false);
            }
        }
    }, [liked, props.id])
    const changeLikeState = () => {
        var likes = localStorage.getItem('likes')
        if (!likes) { likes = ''; }
        var likesArray = likes.split(',');
        var index = likesArray.indexOf(props.id);
        if (index === -1) {
            likesArray.push(props.id);
            setLiked(true);
        } else {
            likesArray.splice(index, 1);
            setLiked(false)
        }
        //console.log(likesArray.toString())
        localStorage.setItem('likes', likesArray.toString());
    }

    return <div className={liked === true ? 'Heart is-liked' : 'Heart'} onClick={(e) => { changeLikeState(); }}>
        <Heart />
    </div>
}

export default LikeButton;