import React, {useState, useEffect} from 'react';
import Header from '../../container/Header/Header.js';
import Footer from '../../container/Footer/Footer.js';
import {Container, Row, Col, Badge} from 'react-bootstrap';
import ReturnLink from './../../components/ReturnLink/ReturnLink.js';
//import CardSubEvent from '../../components/CardSubEvent/CardSubEvent.js';
import CardSubEvent from '../../components/CardSubEventV3/CardSubEventV3.js';
import PastilleDate from './../../components/PastilleDate/PastilleDate.js';
import {Helmet} from "react-helmet";
import axios from "axios";
import variables from "./../../variables.js";

import './Evenement.scss';

const Evenement = (props) => {
    const {id,day} = props;
    const [pageData,setPageData]=useState();
    const [events,setEvents]=useState();
    const [categs,setCategs]=useState();
    const [currCateg,setCurrCateg]=useState();
    useEffect(()=>{
        if(!pageData){
            axios({
                method:'get',
                url:variables.site+'/events/'+id,
                //headers:pixheaders
            }).then(function(response){
                //console.log(response);
                if(response.status === 200){
                    console.log(response.data.data); 
                    setPageData(response.data.data);
                }
            })
        }
        if(pageData && !events){
            //console.log(variables.site+pageData.eventsactivities);
            axios({
                method:'get',
                url:variables.site+pageData.eventsactivities,
                //headers:pixheaders
            }).then(function(response){
                //console.log(response);
                if(response.status === 200){
                    //console.log(response.data.data);
                    //setEvents(Object.keys(response.data.data).sort().reduce((r, k) => (r[k] = response.data.data[k], r), {}));
                    setEvents(response.data.data.sort((a,b) => {
                        let adate = a.date_event.split('-')
                        let bdate = b.date_event.split('-')
                        if(adate[0] > bdate[0]){
                            return 1;
                        }else if(a.heure_debut > b.heure_debut){
                            return 1;
                        }else{
                            return -1;
                        }
                    }));
                    setCurrCateg('Tous')
                    var categs = ['Tous'];
                    response.data.data.map(result=>{
                        if(result.type && !categs.includes(result.type)){
                            let dateEvent = result.date_event.split('-');
                            if(day && day != dateEvent[0]){

                            }else{
                                categs.push(result.type)
                            }
                        }
                        return false;
                    })
                    setCategs(categs);
                }
            })
        }
        //console.log(events);
    })
    if(pageData){
        var date = pageData.date_event.split('-');
        if(day){
            date = new Array(day);
        }
        const returnLinkHref = window.localStorage.getItem('currentsearch') ? window.localStorage.getItem('currentsearch') : '/';
        return (<div className="page-evenement">
            <Helmet>
                <title>{pageData.name} - Fête Nationale du Québec</title>
            </Helmet>
            <div className="bg-head">
                <Header />
                <Container className="mt-8 pb-md-12 pb-5">
                    <ReturnLink href={returnLinkHref} text="Programmation complète" variant="nav" />
                    <div className="d-flex flex-wrap align-items-end justify-content-between">
                        <Col md="8"><h2 className="text-white mt-mb-6 mt-4">{pageData.name}</h2></Col>
                        <Col md="4" className="text-right d-none d-md-block"><PastilleDate date={date[0]} /></Col>
                    </div>
                </Container>
            </div>
            <Container className="pb-12 eventsList">
                <div className="d-block d-md-none"><PastilleDate date={date[0]} /></div>
                <div className="pt-6 pb-6">
                    <div className="eventTags">
                        <div>
                            {(Array.isArray(categs) && categs.length !== 0) && categs.map(categ=>(
                                <Badge pill key={categ} className={currCateg===categ ? "mb-2 is-active" : "mb-2"} style={{cursor:'pointer'}} onClick={()=>{setCurrCateg(categ)}}>{categ}</Badge>
                            ))}
                        </div>
                    </div>
                </div>
                <Row>
                    {(Array.isArray(events) && events.length !== 0) && events.map(result=>{
                        //console.log(result);
                        let dateEvent = result.date_event.split('-');
                        let show = true;
                        /*console.log('day')
                        console.log(day)
                        console.log(dateEvent[0])*/
                        if(result.type !== currCateg && currCateg !== 'Tous'){
                            show = false;
                        }
                        if(day && day != dateEvent[0]){
                            show = false;
                        }
                        if(show){
                            /*return (
                                <Col lg="3" md="4" sm="6" className="mb-3" key={result.id}>
                                    <CardSubEvent
                                        id={result.id}
                                        datetime={result.heure_debut}
                                        thumbnail={result.image_src ? variables.imageSource+result.image_src : null }
                                        titre={result.name}
                                        adresse={result.adresse_site + ', ' + result.city + ', ' + result.code_postal}
                                        categorie={result.type}
                                        subCategorie={result.sub_type}
                                        cancelIfRain={result.weather_condition}
                                        cancelIfRainExtra={result.weather_explication}
                                    />
                                </Col>
                            )*/
                            //console.log(result);
                            return (
                                <Col md="3" sm="6" className="mb-md-3 mb-1" key={result.id}>
                                    <CardSubEvent
                                        id={result.id}
                                        datetime={result.heure_debut}
                                        heure_debut={result.heure_debut}
                                        heure_fin={result.heure_fin}
                                        date={dateEvent[0]+' juin'}
                                        thumbnail={result.image_src ? variables.imageSource+result.image_src : null }
                                        titre={result.name}
                                        adresse={result.adresse_site + ', ' + result.city + ', ' + result.code_postal}
                                        location_nicename={result.lieu_precis}
                                        categorie={result.type}
                                        subCategorie={result.sub_type}
                                        cancelIfRain={result.weather_condition}
                                        cancelIfRainExtra={result.weather_explication}
                                        animation={result.animation}
                                        artiste={result.artist}
                                        chorale={result.chorale}
                                        releve={result.releve}
                                        representants={result.representants ? result.representants : null}
                                    />
                                </Col>
                            )
                        }else{
                            return false;
                        }
                    })}
                </Row>
            </Container>
                
            <Footer/>
        </div>);
    }else{
        return (<div className="page-evenement">
            <Helmet>
                <title>Chargement - Fête Nationale du Québec</title>
            </Helmet>
            <div className="bg-head">
                <Header />
                <Container className="mt-8 pb-12">
                    <ReturnLink href="/" text="Programmation complète" variant="nav" />
                    <div className="d-flex flex-wrap align-items-end justify-content-between">
                        <Col md="6"><h2 className="text-white mt-6">Chargement</h2></Col>
                    </div>
                </Container>
            </div>
        </div>)
    }
}
export default Evenement;