import React from 'react';
import {Router} from '@reach/router';

import Styles from './templates/Styles/Styles.js';
import Programmation from './templates/Programmation/Programmation.js';
import Evenement from './templates/Evenement/Evenement.js';

import {Helmet} from "react-helmet";
import './assets/scss/general.scss';
import Logo from './assets/image/favicon.png';

function App() {
  return (
    <div className="App">
      <Helmet>
        <title>Fête Nationale - Programmation</title>
        <link rel="icon" type="image/png" href={Logo}></link>
        <meta name="theme-color" content="#183970" />
        <link type="text/css" rel="stylesheet" href="//fast.fonts.net/cssapi/2210a8b0-d09d-483b-adf4-9e849f066e71.css"/>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link href="https://fonts.googleapis.com/css2?family=Poppins:wght@700&display=swap" rel="stylesheet"></link>
      </Helmet>
      <Router>
        <Programmation path="/" />
        <Evenement path="/evenement/:id" />
        <Evenement path="/evenement/:id/:day" />
        <Styles path="/styles/" />
      </Router>
    </div>
  );
}

export default App;
