var site = 'https://paf.fetenationale.quebec/api';
var imageSource = 'https://paf.fetenationale.quebec';
if (window.location.href.indexOf("pixelcircus") > -1 || window.location.href.indexOf("localhost") > -1) {
    if(window.location.href.indexOf("loadprod") > -1) {}else{
        console.warn(
            '%c%s',
            'font-size:24px;font-weight:700;',
            'Stage loadé!'
        );
        //site = 'https://paf.mnq.pixelcircusclient.com/api';
        //imageSource = 'https://paf.mnq.pixelcircusclient.com';
    }
}
var variables = {
    site: site,
    imageSource:imageSource
}
export default variables;