import React from 'react';
import {Link} from '@reach/router';
import {ReactComponent as ArrowLeft} from './../../assets/image/arrow_left.svg';
import './ReturnLink.scss'

const ReturnLink = (props) => {
    let {
        href = "#",
        text = "Fournir Texte",
        variant = "a"
     } = props;
    if(variant === "a"){
        return <a href={href} className="ReturnLink"><ArrowLeft />{text}</a>
    }else{
        return (<Link to={href} className="ReturnLink"><ArrowLeft />{text}</Link>)
    }
}
export default ReturnLink;